.custom-form-select.form-select {
    border-radius: 10px;
    border-width: 2px;
    border-color: var(--unlisted-teal)
}

.custom-form-control.form-control {
    border-radius: 10px;
    border-width: 2px;
    border-color: var(--unlisted-teal)
}

.custom-form-select.spaced.form-select {
    margin-left: 11px;
    width: auto;
}
