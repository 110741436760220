.custom-input-group{
    height: 52px;
}

.custom-input-group .form-control{
    border-left: unset !important;
    border-right: unset !important;
    background: var(--unlisted-light-teal);
}

.custom-input-group .form-control:focus{
    border-left: unset !important;
    border-right: unset !important;
    background: var(--unlisted-light-teal);
    box-shadow: unset;
    border-color: transparent;
}

.custom-input-group .input-group-text{
    background: transparent;
}

.custom-input-group .input-group-text:first-child{
    border-top-left-radius: 13px;
    border-bottom-left-radius: 13px;
    background: var(--unlisted-light-teal);
}

.custom-input-group .input-group-text:last-child{
    border-top-right-radius: 13px;
    border-bottom-right-radius: 13px;
    background: var(--unlisted-light-teal);
}

.btn-close {
    position: absolute;
    right: 25px;
    top: 25px;
}
