.card.borders {
    border-radius: 23px;
    border-width: 0;
}

.header-text {
    color: var(--white);
    font-size: clamp(0.9rem, 5.2vw - 1.1rem, 2.2rem);
    font-weight: 500;
}

.header-text:first-letter {
    text-transform: capitalize;
}

.card-header.custom-header {
    margin-left: -12px;
    margin-right: -12px;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 16px;
    background: var(--unlisted-green);
}

.card-header.custom-header:first-child {
    border-top-left-radius: 23px;
    border-top-right-radius: 23px;
}
