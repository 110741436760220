.recipe-borders {
    border-style: dashed;
    border-width: 2px;
    border-right-width: 0;
    border-left-width: 0;
    margin-left: -27px;
    margin-right: -27px;
    border-color: var(--unlisted-teal);
}

.recipe-info {
    border-radius: 10px;
    border-width: 1px;
    border-style: solid;
    border-color: var(--unlisted-teal);
    margin: 15px 10px;
    min-height: 95px;
    background: var(--white);
}
