.subtitle {
    font-family: Montserrat, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 15px;
    color: var(--unlisted-black);
    padding-top: 16px;
    padding-bottom: 16px;
}

.body-bg {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin-bottom: 16px;
}
.my-actions { margin: 0 2em; }
.order-1 { order: 1; }
.order-2 { order: 2; }
.right-gap {
  margin-right: auto;
}
