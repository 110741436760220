.modal-header.custom-modal-header {
    border: unset;
    padding-bottom: unset;
    border-radius: 16px;
}

.modal-body.custom-modal-body {
    padding-top: unset;
}

.custom-modal-fullbleed .modal-body.custom-modal-body {
    height: 100vh;
}

.custom-modal .modal-dialog .modal-content {
    border-radius: 16px;
}

.custom-modal .modal-dialog {
    padding: 15px 15px 10px 15px;
    min-width: 50%;
}

.custom-modal.custom-modal-fullbleed.modal {
    overflow: hidden;
}
