Button.primary-button {
    background: var(--yellow);
    border-radius: 50px;
    height: 31px;
    width: 31px;
    padding: unset;
    border-color: var(--yellow);
}

Button.primary-button:focus {
    background: var(--yellow);
    border-color: var(--yellow);
    box-shadow: 0 0 0 0.25rem var(--unlisted-blue);
}

Button.primary-button:hover {
    background: var(--yellow);
    border-color: var(--yellow);
    box-shadow: 0 0 0 0.25rem var(--unlisted-blue);
}

Button.primary-button:disabled {
    background: var(--yellow);
    border-color: var(--yellow);
}

.MuiMenu-paper {
    border-radius: 13px !important;
    border: 2px solid var(--yellow);
    width: 180px;
    margin-top: 8px;
}

.MuiMenuItem-root:hover {
    background: var(--unlisted-yellow) !important;
    margin-right: 10px;
    margin-left: 11px;
    padding-right: 5px;
    padding-left: 5px;
    border-radius: 8px;
}
