html, body, #root {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Helvetica Neue', 'Montserrat',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./assets/fonts/Montserrat/Montserrat-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 900;
  src: local('Montserrat'), url(./assets/fonts/Montserrat/Montserrat-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 900;
  src: local('Montserrat'), url(./assets/fonts/Montserrat/Montserrat-Black.ttf) format('truetype');
}

:root {
  /* Colors */
  --dark-grey: #CCCCCC;
  --light-grey: #EEEDED;
  --black: #000000;
  --white: #FFFFFF;
  --dark-green: #4F9681;
  --light-green: #9CC6A5;
  --coffee: #39150D;
  --orange: #E0963C;
  --yellow: #F3C74C;
  --teal: #73CBC8;
  --green: #00ff00;
  --red: #FF0000;
  /* unlisted colors */
  --unlisted-green: #5CA2A0;
  --unlisted-blue: #D4E6E6;
  --unlisted-teal: #5CA2A0;
  --unlisted-black: #111212;
  --unlisted-light-teal: #BEDAD9;
  --unlisted-red: #E03C3C;
  --unlisted-light-green: #CEE3E3;
  --unlisted-pink: #E6DDFF;
  --unlisted-purple: #2D2366;
  --unlisted-light-purple: #8FADFF;
  --unlisted-dark-purple: #6D5CA2;
  --unlisted-yellow: #FAE9B7
}

.bold {
  font-weight: 700;
}

.extra-bold {
  font-weight: 800;
}
