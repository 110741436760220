.btn.custom-pill:focus {
    border: unset;
    box-shadow: unset;
}

.btn.custom-pill {
    border-radius: 13px;
    padding: 4px 16px;
    border: unset;
    width: max-content;
}

.btn.custom-pill span {
    font-weight: 700;
    font-size: 12px;
}
